<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
.total-tip {
  margin: 10px 0 10px 0;
  font-size: 14px;
  color: #333333;
}
</style>

<template>
  <!-- 用户管理 -->
  <section class="page-child-contianer">
    <div class="table-box">
      <!-- 搜索表单 -->
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="创建时间段">
          <div class="block">
            <!-- <span class="demonstration">注册时间段</span> -->
            <el-date-picker
              v-model="value1"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="工单类型">
          <el-select v-model="searchForm.work_order_id" placeholder="工单类型">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in workOrderList"
              :key="item.id"
              :label="item.name + `【${item.desc}】`"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位">
          <el-select v-model="searchForm.company_id" placeholder="单位">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="(item._child && true) || false"
            >
              <p>
                <template v-if="item.hierarchy > 0">
                  <span
                    class="hierarchy iconfont"
                    v-for="num in item.hierarchy"
                    :key="num"
                  >
                    &#xe603;
                  </span>
                </template>
                {{ item.name }}
              </p>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目">
          <el-select v-model="searchForm.project_id" placeholder="选择项目">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="searchForm.front_role_id" placeholder="角色">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in frontRoleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="办理员工">
          <el-select
            v-model="searchForm.user_id"
            filterable
            placeholder="办理员工"
          >
            <el-option label="全部" value="" />
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="
                item.name +
                '【' +
                item.company_name +
                '-' +
                item.branch_name +
                '-' +
                item.front_role_name +
                '】'
              "
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理状态">
          <el-select v-model="searchForm.status" placeholder="处理状态">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">
            查询
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            v-if="smallRoleObj.export"
            @click="handleExport"
            icon="el-icon-s-order"
            v-loading="exportLoading"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 数据内容 -->
      <div class="table-container" id="tableContainer">
        <p class="total-tip">共{{ pagination.total }}条数据</p>
        <!-- 内页工具栏 -->
        <div class="tool-bar">
          <div class="tool-left"></div>
          <div class="tool-right">
            <el-tooltip effect="dark" content="刷新">
              <el-button
                size="small"
                icon="el-icon-refresh-right"
                @click="handleRefresh"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="全屏">
              <el-button size="small" @click="handleFullScreen">
                <span class="iconfont">
                  {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                </span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="table-data" ref="tableContainer">
          <!-- 内页表格数据 -->
          <el-table
            v-loading="tableLoading"
            ref="tableBox"
            border
            style="width: 100%"
            :max-height="tableHeight"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column prop="id" label="ID" width="80" align="center" />
            <el-table-column label="创建时间" width="210" align="center">
              <template slot-scope="scope">
                <p>
                  {{
                    (scope.row.create_time * 1000)
                      | formatTime("YYYY-MM-DD HH:mm:ss")
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              prop="work_order_name"
              label="服务类型"
              width="180"
              align="center"
            />

            <el-table-column
              prop="repair_user_name"
              label="服务人员"
              width="180"
              align="center"
            />
            <el-table-column
              prop="company_name"
              label="单位名称"
              align="center"
            />
            <el-table-column
              prop="project_name"
              label="项目名称"
              align="center"
            />
            <el-table-column
              prop="front_role_names"
              label="服务人员角色（工种）"
              align="center"
            />

            <el-table-column prop="status_name" label="状态" align="center" />

            <el-table-column
              label="操作"
              width="240"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  class="color-primary"
                  type="text"
                  v-if="smallRoleObj.detail"
                  icon="el-icon-zoom-in"
                  @click="
                    $router.push({
                      name: 'WORK_ORDER_APPLY_REPORT_DETAIL',
                      params: { id: scope.row.id },
                    })
                  "
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页控件 -->
        <el-pagination
          background
          layout="total,sizes,prev, pager, next,jumper"
          :current-page="pagination.current"
          :page-size="pagination.size"
          :page-sizes="pagination.sizes"
          :total="pagination.total"
          @size-change="handleSize"
          @current-change="handleCurrent"
        >
        </el-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import {
  workOrderApplyReport,
  workOrderApplyReportExport,
  workOrderApplyDetail,
} from "@api/workOrderApply";
import { getCompanyListTree, getCompanyList } from "@api/company";
import { getWorkOrderList } from "@api/workOrder";
import { getProjectList } from "@api/project";
import { getFrontRoleList } from "@api/frontRole";
import { getStaffListAll } from "@api/user";
import { PAGE_SIZE, PAGE_SIZES } from "@config";
export default {
  name: "workOrderApplyBook",
  inject: ["reload"],
  data() {
    return {
      tableLoading: false,
      exportLoading: false,
      tableHeight: 0, //表格最大高度
      searchForm: {}, //搜索表单
      isFull: false, //全屏开启
      tableData: [], //表格数据
      multipleSelection: [], //表格多选储存数据

      statusList: [
        {
          value: "10",
          name: "待接单",
        },
        {
          value: "11",
          name: "待分配",
        },
        {
          value: "12",
          name: "待到达",
        },
        {
          value: "13",
          name: "待完成",
        },
        {
          value: "14",
          name: "待验收",
        },
        {
          value: "15",
          name: "待判定",
        },
        {
          value: "8",
          name: "待评价",
        },
        {
          value: "9",
          name: "已完成",
        },
      ],
      companyList: [], //单位列表
      workOrderList: [], //工单(类型)列表
      projectList: [], //项目列表
      frontRoleList: [], //角色列表
      userList: [], //处理人员列表

      pickerOptions: {
        //时间段组件配置
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: [], //时间段默认时间
      smallRoleObj: {},
      pagination: {
        page: 1,
        total: 0,
        current: 1,
        size: PAGE_SIZE,
        sizes: PAGE_SIZES,
      }, //分页数据
    };
  },
  computed: {
    ...mapState("system", ["branchListSearch"]),
  },
  async created() {
    // 获取初始化数据
    let res = await getCompanyList();
    this.companyList = res.data;
    let res2 = await getWorkOrderList();
    this.workOrderList = res2.data;
    let res3 = await getProjectList();
    this.projectList = res3.data;
    let res4 = await getFrontRoleList();
    this.frontRoleList = res4.data;
    let res5 = await getStaffListAll();
    this.userList = res5.data;

    let tempParams = {
      page: this.pagination.page,
      rows: this.pagination.size,
    };
    this.getData(tempParams);
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
    });
  },
  async mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
    this.smallRoleObj = await this.validateSmallRole();
    this.$forceUpdate();
  },
  methods: {
    /** 获取表格数据 */
    async getData(tempParams) {
      this.tableLoading = true;
      tempParams = {
        ...this.searchForm,
        ...tempParams,
        time_start: (this.value1 && this.value1[0]) || "",
        time_end: (this.value1 && this.value1[1]) || "",
      };
      /**
       * 请求接口数据
       */
      let res = await workOrderApplyReport(tempParams);

      this.tableData = res.data.list;

      this.pagination.total = res.data.count;
      this.tableLoading = false;
      this.$forceUpdate();
    },
    /** 分页参数-每页显示条数改变 */
    handleSize(val) {
      this.pagination = {
        ...this.pagination,
        size: val,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 分页参数-当前页改变 */
    handleCurrent(val) {
      this.pagination = {
        ...this.pagination,
        page: val,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 查询 */
    handleSearch() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /**
     * 导出
     */
    async handleExport() {
      this.exportLoading = true;
      let tempParams = {
        ...this.searchForm,
        time_start: (this.value1 && this.value1[0]) || "",
        time_end: (this.value1 && this.value1[1]) || "",
      };
      await workOrderApplyReportExport(tempParams);
      this.exportLoading = false;
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["tableContainer"]) {
        this.tableHeight = this.$refs["tableContainer"].offsetHeight;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    /** 表格多选触发 */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCancle() {
      this.dialogFormVisible = false;
      this.mobileUpdateId = 0;
      this.mobileUpdateForm = {};
    },
  },
};
</script>
